<template>
	<div class='advertisement'></div>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Advertisement',
	data() {
		return {};
	},
	computed: {
		...mapState(['userInfo'])
		},
};
</script>
<style lang='less' scoped>
</style>